.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;
  min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.voice-type {
  position: fixed;
  right: 20px;
  top: 20px;
}

@media (min-width: 1024px) {

  .App-main {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
  }

  .input-area div {
    text-align: right;
    margin-bottom: 1em;
  }

  .inputTextArea {
    height: 20em;
  }

  .toolbar {
    margin: 0 2em;
  }

  .toolbar div {
    padding-bottom: 1em;
  }

  .toolbar button {
    width: 8em;
    padding: 0.3em;
  }

  .delay-seconds input {
    width: 3em;
  }

  .word-list {
    width: 15em;
  }

  .word-item {
    margin-top: 0.1em;
    padding: 0.5em;
    background-color: #EEE;
  }

  .word-unselected {
    border: solid 1px #FFFFFF;
  }

  .word-selected {
    border: solid 1px #291854;
  }

  /* @media (min-width: 1024px) end */
}



.div-video {
  display: none;
}

.row-item {
  margin-top: 1em;
}

.delete-button {
  border-radius: 50%;
}

.mask {
  background-color: rgba(0, 0, 0, 1);
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.mask-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -4em;
}

.cancel-button {
  margin-top: 1em;
}

.cancel-button button {
  padding: 0.3em 1em;
  font-size: 1.2em;
}